hr {
  border-color: gray;
  width: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.ml-auto {
  margin-left: auto;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}
.br-1 {
  border-right: 1px solid lightgray;
}
.border {
  border: 2px solid black;
}
.align-self-center {
  align-self: center;
}
::ng-deep .w-50 {
  width: 50%;
}
