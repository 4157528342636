/* Webfont: EurobankSans-Regular */@font-face {
    font-family: 'EurobankSans';
    src: url('EurobankSans-Regular.eot'); /* IE9 Compat Modes */
    src: url('EurobankSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('EurobankSans-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('EurobankSans-Regular.woff') format('woff'), /* Modern Browsers */
         url('EurobankSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('EurobankSans-Regular.svg#EurobankSans-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
}

p {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}