/* You can add global styles to this file, and also import other style files */
html {
  height: 100%;
}
body {
  display: flex;
  margin: 0;
  background-color: rgb(243, 243, 243);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw;
  erbfactors-root {
    height: inherit;
    width: inherit;
    display: flex;
    flex-grow: 1;
    .dx-widget {
      font-family: 'EurobankSansLight' !important;
    }
    .mat-expansion-panel-header,
    .mat-list-item {
      padding: 0 21px;
      font-family: 'EurobankSans' !important;
    }
  }
}

.onHover {
  cursor: pointer;
  &:hover {
    background-color: rgba(211, 211, 211, 0.2);
  }
}
// Remove Counter from Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}


.material-icons { color:  #254185; }

dx-data-grid .dx-datagrid-headers .dx-column-lines{
  font-weight: bold;
  color: #0C6DB3;
  font-family: 'EurobankSans';
}