.color-blue {
  color: #0c2c70;
}
.color-gray {
  color: #b6c0d4;
}
.blue-bg-color {
  background-color: #0b2c6f;
}
.color-white {
  color: white;
}
.color-bg-gray {
  background-color: #b6c0d4;
}
.color-black {
  color: black;
}
