@import '~@angular/material/theming';
@import './assets/fonts/EurobankSans-Light.css';
@import './assets/fonts/EurobankSans-Regular.css';

@include mat-core();
$custom-app-primary: mat-palette($mat-gray, 400);
$custom-app-accent: mat-palette($mat-blue, 900);
$custom-app-warn: mat-palette($mat-red, 900);
$custom-typography: mat-typography-config(
  $font-family: 'EurobankSansLight' !important,
  $headline: mat-typography-level(32px, 48px, 700),
);
@include mat-base-typography($custom-typography);
// $button-accent-color: mat-palette($mat-gray, 800);
// $mat-button-theme: mat-light-theme($madosta-app-primary, $button-accent-color);
$custom-app-theme: mat-light-theme(
  $custom-app-primary,
  $custom-app-accent,
  $custom-app-warn
);
@include angular-material-theme($custom-app-theme);
@include angular-material-typography($custom-typography);
// @include mat-checkbox-theme($mat-button-theme);
