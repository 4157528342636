/* Webfont: EurobankSans-Light */@font-face {
    font-family: 'EurobankSansLight';
    src: url('EurobankSans-Light.eot'); /* IE9 Compat Modes */
    src: url('EurobankSans-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('EurobankSans-Light.woff2') format('woff2'), /* Modern Browsers */
         url('EurobankSans-Light.woff') format('woff'), /* Modern Browsers */
         url('EurobankSans-Light.ttf') format('truetype'), /* Safari, Android, iOS */
         url('EurobankSans-Light.svg#EurobankSans-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
}

p {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}