.thread-title {
  width: 40%;
}
.font-small {
  font-size: 0.9rem !important;
}
.font-large {
  font-size: 1.2rem !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.title {
  font-family: 'EurobankSansLight' !important;
  display: inline-flex;
  letter-spacing: 3px;
}
section,
a {
  text-decoration: none;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.4rem;
}
h3 {
  font-size: 1.3rem;
}
h4 {
  font-size: 1.2rem;
}

@media (max-width: 425px) {
  * {
    font-size: 0.85rem;
  }
}
@media (max-width: 825px) {
  * {
    font-size: 0.9rem;
  }
}
