.mat-button {
  border: 1px solid #0b2c6f;
  padding: 1rem 2rem;
}
.mat-card-actions {
  padding-bottom: 1rem !important;
  margin-bottom: 0;
  margin-top: 2rem;
  margin-left: 0;
  margin-right: 0;
  .mat-button {
    margin: 0;
  }
}
mat-hint {
  font-size: smaller;
}
mat-error {
  font-size: smaller;
}
::ng-deep .mat-card-actions {
  padding-bottom: 1rem !important;
  margin-bottom: 0;
  margin-top: 2rem;
  margin-left: 0;
  margin-right: 0;
  .mat-button {
    margin: 0;
  }
}
.mat-form-field-wrapper {
  padding-bottom: 0;
}
.mat-raised-button,
.mat-button {
  min-width: unset !important;
  border-radius: 20px !important;
}

.mat-form-field,
label {
  cursor: inherit;
}
