.snackbar-success {
  background-color: rgb(11, 44, 111);
  color: white;
}
.snackbar-error {
  background-color: darkred;
  color: white;
}
.mat-simple-snackbar-action {
  color: lightgray;
}
.row {
  display: flex;
}
.grow {
  //flex-grow: 1;
  flex: 1;
}
.seperator {
  border: 1px solid lightgray;
}
.seperator {
  border: 1px solid lightgray;
}
.display-none {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.asterisk {
  color: red;
}
.h-100 {
  height: 100%;
}
.flex-1 {
  flex: 1;
}
.flex-basis-0 {
  flex-basis: 0;
}
.control-container {
  padding: 5px 7px;
}
.field-for-edit {
  background-color: rgba(240, 128, 128, 0.2);
  border-radius: 10px;
}
.lable-color
{
  color:red;
}
.visibility-hidden {
  visibility: hidden;
}
.form-title {
  font-size: large;
  border: 1px solid #b6c0d481;
  border-radius: 10px;
  box-shadow: 1px 2px 2px #b6c0d4;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
}
// .card-container {
//   min-width: 1100px;

//   max-width: 1300px;
// }
.dx-datagrid {
  background-color: inherit !important;
}
.hint-text {
  margin-top: 1rem;
  text-align: center;
  font-size: larger;
  color: rgb(92, 92, 92);
}
.active {
  background-color: rgba(128, 128, 128, 0.2);
}
.border-right-white-1 {
  border-right: 1px solid white;
}
.border-bottom-gray-1 {
  border-bottom: 1px solid lightgray;
}
